import { theme } from "../theme";

export const borderRightStyle: React.CSSProperties = {
  borderRightColor: theme.lines,
  borderRightStyle: "solid",
  borderRightWidth: 1,
};

export const inputStyles: React.CSSProperties = {
  border: 0,
  height: 66,
  borderRadius: 30,
  boxShadow: "none",
  borderColor: theme.lines,
  transition: "none",
};

export const borderBottomStyle: React.CSSProperties = {
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: theme.lines,
};

export const borderTopStyle: React.CSSProperties = {
  borderTopWidth: 1,
  borderTopStyle: "solid",
  borderTopColor: theme.lines,
};
