import { Flex } from "@aws-amplify/ui-react";
import { HAListing } from "../libs/shared/types/pms-hostway-listing";

export default function PropertyDescriptionSection({
  listing: { airbnbSummary },
}: {
  listing: HAListing;
}) {
  return (
    <Flex direction="row" gap="20px">
      <Flex whiteSpace="pre-wrap">{airbnbSummary}</Flex>
    </Flex>
  );
}
