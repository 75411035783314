import { useCallback } from "react";

const useNavigateToExternal = () => {
  const navigateToExternal = useCallback((url: string, newTab = true) => {
    if (newTab) {
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      window.location.href = url;
    }
  }, []);

  return navigateToExternal;
};

export default useNavigateToExternal;
