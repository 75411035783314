import { SelectField } from "@aws-amplify/ui-react";
import { MdTranslate } from "react-icons/md";
import i18n from "../../i18n/i18n";

export default function LanguageSelectField() {
  return (
    <SelectField
      height={45}
      minWidth={20}
      direction={"row"}
      border={"none"}
      value={i18n.language}
      onChange={(event) => {
        i18n.setLanguage(event.target.value);
      }}
      label=""
      icon={<MdTranslate></MdTranslate>}
    >
      <option value="pl">PL</option>
      <option value="en">EN</option>
    </SelectField>
  );
}
