import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { useMemo } from "react";
import { UserDetails } from "../libs/shared/types/user-details";
import { prepareHerokuRequest } from "./common";

export const useCurrentUser = () => {
  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  return useMemo(() => {
    const isMemberOfGroup = (group: string) => {
      try {
        return Boolean(
          user
            ?.getSignInUserSession()
            ?.getIdToken()
            .payload["cognito:groups"].includes(group)
        );
      } catch (_err) {
        return false;
      }
    };

    const cognitoId = user?.getUsername();
    const isAdmin = isMemberOfGroup("Admin");
    const isCoordinator = isMemberOfGroup("Coordinator");

    return { authStatus, cognitoId, isAdmin, isCoordinator, user };
  }, [authStatus, user]);
};

export const getCurrentUserData = async (): Promise<UserDetails> =>
  await API.get("herokuapi", `/v1/customer/user`, await prepareHerokuRequest());
