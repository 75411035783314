import { Button, Card, Flex, Heading, Image } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputDates from "../Discovery/components/InputDates";
import NumberOfGuestField from "../Discovery/components/NumberOfGuestField";
import TextMuted from "../libs/shared/components/TextMuted";
import { HABookableListing } from "../libs/shared/hooks/useProperty";
import { useSearchParamsUtils } from "../libs/shared/hooks/useSearchParamsUtils";
import { dateToYYMMDD } from "../libs/shared/utils/dates.util";
import { InquiryParam } from "./InquiryParam";
import PropertyInquiryPricing from "./PropertyInquiryPricing";

export default function PropertyInquiryForm({
  onChange,
  onSelected,
  imageUrl,
  listing: { id, blockedDates, name, personCapacity },
}: {
  imageUrl?: string;
  onChange?: (params: InquiryParam) => void;
  onSelected?: (params: InquiryParam) => void;
  listing: HABookableListing;
}) {
  const { t } = useTranslation();
  const { searchParams, setMultipleSearchParams } = useSearchParamsUtils();
  const [query, setQuery] = useState<InquiryParam>({
    arrival: searchParams.get("arrival")!,
    departure: searchParams.get("departure")!,
    numberOfGuests: Number(searchParams.get("numberOfGuests")! || 2),
  });

  useEffect(() => {
    setMultipleSearchParams({ ...query });
  }, [query]);

  return (
    <Flex direction="column" gap="20px" grow={1}>
      <Card variation="elevated">
        {imageUrl && (
          <>
            <Flex
              marginBottom={12}
              maxWidth={450}
              direction={"column"}
              grow={1}
              style={{
                marginLeft: "-16px",
                marginRight: "-16px",
                marginTop: "-16px",
              }}
            >
              <Image
                grow={1}
                maxWidth={450}
                maxHeight={200}
                alt={"Book with RENTUJEMY.COM"}
                src={imageUrl}
              ></Image>
            </Flex>
            <Flex marginBottom={12}>
              <Heading level={3}>{name}</Heading>
            </Flex>
          </>
        )}
        <Flex direction={"column"}>
          <InputDates
            exclude={blockedDates}
            onChange={(dates) => {
              const newQuery = {
                ...query,
                arrival: dateToYYMMDD(dates.arrival!),
                departure: dateToYYMMDD(dates.departure!),
              };

              setQuery(newQuery);
            }}
          ></InputDates>
          <NumberOfGuestField
            capacity={personCapacity}
            defaultValue={Number(searchParams.get("numberOfGuests")!) || 2}
            onChange={(numberOfGuests) => {
              const newQuery = { ...query, numberOfGuests };

              setQuery(newQuery);
            }}
          ></NumberOfGuestField>

          {onSelected && (
            <Flex direction={"column"} gap={4}>
              <Button
                disabled={
                  !query.arrival || !query.departure || !query.numberOfGuests
                }
                variation="primary"
                onClick={() => {
                  onSelected(query);
                }}
              >
                {t("Property.Book")}
              </Button>
              <TextMuted style={{ textAlign: "center", fontSize: 12 }}>
                {t("Property.NoPaymentYet")}
              </TextMuted>
            </Flex>
          )}

          <PropertyInquiryPricing listing={{ id }} inquiry={query} />
        </Flex>
      </Card>
    </Flex>
  );
}
