import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { apiEndpoint } from "../config";
import { useCreateApi } from "../libs/shared/hooks/useLazyApi";
import { getAuthHeader } from "./common";
import { GuestReservation } from "./guest-reservation";

interface InquiryReturnType {
  /**
   * URL to Stripe Checkout Session
   */
  paymentUrl: string | null;
  /**
   * ID of the generated Hostaway reservation of status `inquiry`
   */
  id: number;
}

export const useCreateInquiry = () =>
  useCreateApi<
    {
      id?: number;
      hostawayId: number;
      checkinDate: string;
      checkoutDate: string;
      numberOfGuests: number;
      guestFirstName: string;
      guestLastName: string;
      guestEmail: string;
      phone: string;
      guestLocale: string;
    },
    InquiryReturnType
  >("/v1/guest/reservations/manager/inquiry");

const getGuestReservations = async (): Promise<{
  reservations: GuestReservation[];
}> =>
  (
    await axios.get(
      `${apiEndpoint}/v1/guest/reservations/manager/reservations`,
      await getAuthHeader()
    )
  ).data;

export const useGuestReservations = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GuestReservation[]>();
  const [error, setError] = useState<unknown>();

  const refetch = useCallback(() => {
    setLoading(true);

    (async () => {
      try {
        setData((await getGuestReservations()).reservations);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [setLoading, setData, setError]);

  useEffect(() => {
    if (!data) {
      refetch();
    }
  }, [refetch]);

  return { data, loading, error, refetch };
};
