import styled from "styled-components";

interface BoxProps {
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  flexBasis?: string;
  alignItems?: string;
  textAlign?: "center";
  flexDirection?: string;
  flexGrow?: number;
  backgroundColor?: string;
  justifyContent?: string;
}

export default styled.div`
    ${(p) => (p.flexBasis ? `flex-basis: ${p.flexBasis}%;` : "")}
    ${(p: BoxProps) => (p.mt ? `margin-top: ${p.mt}px;` : "")}
    ${(p: BoxProps) => (p.mb ? `margin-bottom: ${p.mb}px;` : "")}
    ${(p: BoxProps) => (p.ml ? `margin-left: ${p.ml}px;` : "")}
    ${(p: BoxProps) => (p.mr ? `margin-right: ${p.mr}px;` : "")}
    ${(p: BoxProps) => (p.pt ? `padding-top: ${p.pt}px;` : "")}
    ${(p: BoxProps) => (p.pb ? `padding-bottom: ${p.pb}px;` : "")}
    ${(p: BoxProps) => (p.pl ? `padding-left: ${p.pl}px;` : "")}
    ${(p: BoxProps) => (p.textAlign ? `text-align: ${p.textAlign};` : "")}
    ${(p: BoxProps) => (p.pr ? `padding-right: ${p.pr}px;` : "")}
    ${(p: BoxProps) => (p.flexGrow ? `flex-grow: ${p.flexGrow};` : "")}
    ${(p: BoxProps) =>
      p.flexDirection ? `flex-direction: ${p.flexDirection};` : ""}
    ${(p: BoxProps) => (p.alignItems ? `align-items: ${p.alignItems};` : "")}
    ${(p: BoxProps) =>
      p.justifyContent ? `justify-content: ${p.justifyContent};` : ""}
    ${(p: BoxProps) =>
      p.backgroundColor ? `background-color: ${p.backgroundColor};` : ""}
}>`;
