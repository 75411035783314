import { Flex, Heading } from "@aws-amplify/ui-react";
import { HAListing } from "../libs/shared/types/pms-hostway-listing";
import { useTranslation } from "react-i18next";

export default function PropertyDiscounts({
  listing: { monthlyDiscount, weeklyDiscount },
}: {
  listing: HAListing;
}) {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap="20px">
      <Flex>
        <Heading level={2}>{t("Property.Discounts")}</Heading>
      </Flex>
      <Flex>
        <Flex direction={"column"} basis={"40%"}>
          <Heading level={3}>{t("Inquiry.MonthlyDiscount")}</Heading>
          <Flex>{Math.round((1 - monthlyDiscount) * 100)}%</Flex>
        </Flex>
        <Flex direction={"column"} basis={"40%"}>
          <Heading level={3}>{t("Inquiry.WeeklyDiscount")}</Heading>
          <Flex>{Math.round((1 - weeklyDiscount) * 100)}%</Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
