import { Flex } from "@aws-amplify/ui-react";
import { isMobile, isSafari } from "react-device-detect";
import { NAV_TABS_HEIGHT } from "../../../Discovery/components/MobileNavTabs";
import { useIsMatchingMobile } from "../hooks/useIsMatchingMobile";

export default function Container({ children }: React.PropsWithChildren) {
  const isMatchingMobile = useIsMatchingMobile();

  return (
    <>
      <Flex
        grow={1}
        gap={0}
        direction="column"
        overflow={"hidden"}
        height={
          isMatchingMobile
            ? `calc(
        100svh -
          ${NAV_TABS_HEIGHT}px -
          ${isMobile && isSafari ? "env(safe-area-inset-bottom) + 0px" : "0px"}
      )`
            : undefined
        }
      >
        <Flex className="container" paddingTop={30}>
          {children}
        </Flex>
      </Flex>
    </>
  );
}
