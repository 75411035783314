import { useEffect } from "react";
import { useLazyApi } from "./useLazyApi";

export const useApi = <T extends Object, Q>(relativeUrl: string) => {
  const { load, data, loading, error } = useLazyApi<T, Q>(relativeUrl);

  useEffect(() => {
    load();
  }, [load]);

  return { data, loading, error, refetch: load };
};
