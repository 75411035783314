import { Text, TextProps } from "@aws-amplify/ui-react";
import { theme } from "../../../theme";
import { PropsWithChildren } from "react";

export default function TextMuted(
  props: PropsWithChildren<Pick<TextProps, "style">>
) {
  return (
    <Text style={props.style} color={theme.muted}>
      {props.children}
    </Text>
  );
}
