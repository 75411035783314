import { lazy, Suspense } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import LoaderBox from "../libs/shared/components/LoaderBox";

const OwnerIncomeCalculatorPage = lazy(
  () => import("./OwnerIncomeCalculatorPage")
);

const incomeCalculatorRoutes: RouteObject[] = [
  {
    path: "/short-term-rental-calculator",
    element: (
      <Suspense fallback={<LoaderBox />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: <OwnerIncomeCalculatorPage />,
      },
      {
        path: ":city",
        element: <OwnerIncomeCalculatorPage />,
      },
      {
        path: ":city/:region",
        element: <OwnerIncomeCalculatorPage />,
      },
      {
        path: ":city/:region/:type",
        element: <OwnerIncomeCalculatorPage />,
      },
      {
        path: ":city/:region/:type/:standard",
        element: <OwnerIncomeCalculatorPage />,
      },
    ],
  },
];

export default incomeCalculatorRoutes;
