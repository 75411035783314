import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
import { dateToYYMMDD } from "../../libs/shared/utils/dates.util";

import "./react-datepicker.overrides.css";
import { usePropertyBlockedDates } from "../../Property/hooks/usePropertyBlockedDates";
import { Button, Flex } from "@aws-amplify/ui-react";

export interface ReservationDateRange {
  start?: string;
  end?: string;
}

export default function StayDatePickerRanger({
  exclude,
  monthsShown = 2,
  defaultValues,
  onChange,
}: {
  exclude: ReservationDateRange[];
  onChange: (props: ReservationDateRange) => void;
  monthsShown?: number;
  defaultValues?: ReservationDateRange;
}) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | undefined>(
    defaultValues?.start ? new Date(defaultValues.start) : undefined
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    defaultValues?.end ? new Date(defaultValues.end) : undefined
  );

  const { excludeEndDates, excludeStartDates } = usePropertyBlockedDates({
    reservations: exclude,
    selectedStartDate: startDate,
  });

  useEffect(() => {
    onChange({
      start: startDate ? dateToYYMMDD(startDate) : undefined,
      end: endDate ? dateToYYMMDD(endDate) : undefined,
    });
  }, [startDate, endDate, onChange]);

  return (
    <Flex direction={"column"}>
      <DatePicker
        excludeDateIntervals={excludeEndDates?.concat(excludeStartDates)}
        dayClassName={() => "react-picker-day"}
        monthsShown={monthsShown}
        locale={i18n.language}
        placeholderText={t("Discovery.Search.AddDates")}
        selected={startDate}
        dateFormat={"d.MM.YYYY"}
        onChange={(date) => {
          setStartDate(date[0] || undefined);
          setEndDate(date[1] || undefined);
        }}
        startDate={startDate}
        minDate={new Date()}
        endDate={endDate}
        selectsRange
        inline
      />
      <Button
        variation="link"
        onClick={() => {
          setStartDate(undefined);
          setEndDate(undefined);
        }}
      >
        {t("Property.ClearDates")}
      </Button>
    </Flex>
  );
}
