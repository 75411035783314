import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { APIProvider } from "@vis.gl/react-google-maps";
import { lazy, Suspense } from "react";
import { Outlet, RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import "./App.scss";
import { DISCOVERY_LINK } from "./Discovery/links";
import GuestRoutes from "./Guest/routes";
import OwnerIncomeCalculatorRoutes from "./OwnerIncomeCalculatorPage/routes";
import RentalOnboardingPage from "./RentalOnboardingPage/RentalOnboardingPage";
import { GOOGLE_MAPS_API } from "./config";
import LoaderBox from "./libs/shared/components/LoaderBox";
import { logger } from "./logger";
import CustomerRoutes from "./routes";
import GuestPropertyPage from "./Property/PropertyPage";
import PropertyInquiryPage from "./Property/PropertyInquiryPage";
import DiscoveryWrapper from "./Discovery/DiscoveryWrapper";

const RealEstateROICalculatorPage = lazy(
  () => import("./RealEstateROICalculatorPage/RealEstateROICalculatorPage")
);

const DiscoverySearchPage = lazy(
  () => import("./Discovery/DiscoverySearchPage")
);

const DiscoveryPage = lazy(() => import("./Discovery/DiscoveryPage"));
const LoginComponent = lazy(() => import("./Auth/LoginPage"));

export default function App() {
  return (
    <AmplifyProvider>
      <Authenticator.Provider>
        <RouterProvider
          router={createBrowserRouter(
            [
              {
                path: "",
                element: (
                  <Suspense fallback={<LoaderBox />}>
                    <DiscoveryWrapper>
                      <Outlet />
                    </DiscoveryWrapper>
                  </Suspense>
                ),
                children: [
                  {
                    path: DISCOVERY_LINK,
                    element: (
                      <APIProvider
                        apiKey={GOOGLE_MAPS_API}
                        onLoad={() => logger.log("Maps API has loaded.")}
                      >
                        <Suspense fallback={<LoaderBox />}>
                          <DiscoveryPage />
                        </Suspense>
                      </APIProvider>
                    ),
                  },
                  {
                    path: "/s/:searchString",
                    element: (
                      <APIProvider
                        apiKey={GOOGLE_MAPS_API}
                        onLoad={() => logger.log("Maps API has loaded.")}
                      >
                        <Suspense fallback={<LoaderBox />}>
                          <DiscoverySearchPage />
                        </Suspense>
                      </APIProvider>
                    ),
                  },
                  {
                    path: "listings/:propertyId",
                    element: <GuestPropertyPage />,
                  },
                  {
                    path: "listings/:propertyId/inquiry",
                    element: <PropertyInquiryPage />,
                  },
                ],
              },
              {
                path: "/login",
                element: (
                  <Suspense fallback={<LoaderBox />}>
                    <LoginComponent />
                  </Suspense>
                ),
              },
              {
                path: "/login",
                element: (
                  <Suspense fallback={<LoaderBox />}>
                    <LoginComponent />
                  </Suspense>
                ),
              },

              ...CustomerRoutes,
              ...GuestRoutes,
              ...OwnerIncomeCalculatorRoutes,
              {
                path: "/kalkulator-zwrotu-z-inwestycji-w-nieruchomosc",
                element: <RealEstateROICalculatorPage />,
              },
              {
                path: "/rental-onboarding",
                element: <RentalOnboardingPage />,
              },
            ],
            {}
          )}
        />
      </Authenticator.Provider>
    </AmplifyProvider>
  );
}
