import { Button, Flex, StepperField, Text } from "@aws-amplify/ui-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { borderBottomStyle } from "../Discovery/commonStyles";
import StayDatePickerRanger, {
  ReservationDateRange,
} from "../Discovery/components/StayDatePickerRange";
import Headline2 from "../libs/shared/components/Headline2";
import { HABookableListing } from "../libs/shared/hooks/useProperty";
import { InquiryParam } from "./InquiryParam";
import PropertyInquiryPricing from "./PropertyInquiryPricing";

export default function PropertyInquiryBottomsheet({
  open,
  onSelected,
  onDismiss,
  listing,
  defaultInquiry,
}: {
  defaultInquiry?: InquiryParam;
  listing: HABookableListing;
  open: boolean;
  onDismiss: () => void;
  onSelected: (inquiry: InquiryParam) => void;
}) {
  const [inquiry, setInquiry] = useState<InquiryParam>({
    numberOfGuests: 2,
    ...defaultInquiry,
  });
  const { t } = useTranslation();

  const handleDateChange = useCallback(
    (dates: ReservationDateRange) => {
      setInquiry({
        ...inquiry,
        arrival: dates.start,
        departure: dates.end,
      });
    },
    [setInquiry]
  );

  return (
    <BottomSheet
      onDismiss={onDismiss}
      open={open}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
    >
      <Flex direction={"column"} grow={1}>
        <Flex
          paddingLeft={16}
          paddingRight={16}
          style={{ ...borderBottomStyle }}
        >
          <Headline2>{t("Property.CheckAvailability")}</Headline2>
        </Flex>

        <Flex direction={"column"} style={borderBottomStyle}>
          <Flex justifyContent={"center"}>
            <Flex
              direction={"column"}
              alignItems={"space-between"}
              padding="16px"
              grow={1}
            >
              <Flex>
                <Flex>
                  <StepperField
                    width={"100%"}
                    max={listing.personCapacity}
                    min={1}
                    defaultValue={inquiry.numberOfGuests}
                    step={1}
                    borderRadius="20px"
                    label={t("Discovery.Search.Guests")}
                    placeholder={t("Discovery.Search.Guests")}
                    onStepChange={(newValue) => {
                      setInquiry({
                        ...inquiry,
                        numberOfGuests: newValue,
                      });
                    }}
                  />
                </Flex>
              </Flex>

              <Flex direction={"column"}>
                <Text>{t("Discovery.Dates.Header")}</Text>
                <StayDatePickerRanger
                  exclude={listing.blockedDates}
                  monthsShown={1}
                  defaultValues={{
                    start: defaultInquiry?.arrival,
                    end: defaultInquiry?.departure,
                  }}
                  onChange={handleDateChange}
                />
              </Flex>

              <PropertyInquiryPricing listing={listing} inquiry={inquiry} />

              <Flex grow={1} width={"100%"}>
                <Button
                  disabled={
                    !inquiry.arrival ||
                    !inquiry.departure ||
                    !inquiry.numberOfGuests
                  }
                  position={"absolute"}
                  bottom={16}
                  left={16}
                  right={16}
                  height={60}
                  variation="primary"
                  display="flex"
                  fontSize={22}
                  alignItems="center"
                  justifyContent="center"
                  onClick={() => onSelected(inquiry)}
                >
                  {t("Property.Book")}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </BottomSheet>
  );
}
