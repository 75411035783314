import { Flex, Heading, Text } from "@aws-amplify/ui-react";

export default function HeadingSection({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  return (
    <Flex direction={"column"}>
      <Flex direction={"row"} alignItems={"center"}>
        <Flex>
          <Heading level={3}>{title}</Heading>
        </Flex>
      </Flex>

      <Flex>
        <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
          {subtitle}
        </Text>
      </Flex>
    </Flex>
  );
}
