import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useSearchParamsUtils = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSingleSearchParam = useCallback(
    (key: string, value: string | number | undefined, commit = true) => {
      if (value) {
        searchParams.set(key, String(value));
      } else {
        searchParams.delete(key);
      }

      if (commit) {
        setSearchParams(searchParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const setMultipleSearchParams = useCallback(
    (params: Record<string, string | number | undefined>, commit = true) => {
      Object.keys(params).forEach((key) => {
        setSingleSearchParam(key, params[key], false);
      });

      if (commit) {
        setSearchParams(searchParams);
      }

      return searchParams;
    },
    [setSearchParams, searchParams, setSingleSearchParam]
  );

  return { searchParams, setMultipleSearchParams, setSingleSearchParam };
};
