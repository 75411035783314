import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

export default function Redirect({
  to,
  paramNames = [],
}: {
  to: string;
  paramNames: string[];
}) {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let resolved = to;

    for (const paramName of paramNames) {
      resolved = resolved.replace(`:${paramName}`, params[paramName]!);
    }

    navigate(resolved);
  });

  return <Outlet />;
}
