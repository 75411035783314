import {
  Alert,
  Button,
  CheckboxField,
  Divider,
  Flex,
  Heading,
  Link,
  TextField,
} from "@aws-amplify/ui-react";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { MdChevronLeft, MdDateRange, MdEdit, MdPerson } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import DiscoveryHeader from "../Discovery/components/DiscoveryHeader";
import { TextMuted } from "../Discovery/components/DiscoveryTypography";
import { InquiryErrors } from "../Guest/types/InquiryErrors";
import { useCreateInquiry } from "../hooks/useGuestReservations";
import i18n from "../i18n/i18n";
import Container from "../libs/shared/components/Container";
import Loader from "../libs/shared/components/Loader";
import { privacyLink, termsLink } from "../libs/shared/constants";
import useForm from "../libs/shared/hooks/useForm";
import { useIsMatchingMobile } from "../libs/shared/hooks/useIsMatchingMobile";
import useNavigateToExternal from "../libs/shared/hooks/useNavigateToExternal";
import { useProperty } from "../libs/shared/hooks/useProperty";
import { useSearchParamsUtils } from "../libs/shared/hooks/useSearchParamsUtils";
import { InquiryParam } from "./InquiryParam";
import PropertyInquiryBottomsheet from "./PropertyInquiryBottomsheet";
import PropertyInquiryForm from "./PropertyInquiryForm";

export default function PropertyInquiryPage() {
  const isMatchingMobile = useIsMatchingMobile();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ propertyId: string }>();
  const { searchParams } = useSearchParamsUtils();
  const { data } = useProperty(Number(params.propertyId));
  const { formValues, setFieldValue, setFormValues } = useForm<{
    marketingOptOut: boolean;
    hostawayId?: number;
    checkinDate?: string;
    checkoutDate?: string;
    numberOfGuests?: number;
    guestFirstName?: string;
    guestLastName?: string;
    guestEmail?: string;
    phone?: string;
  }>({
    marketingOptOut: false,
    hostawayId: Number(params.propertyId),
    checkinDate: searchParams.get("arrival")!,
    checkoutDate: searchParams.get("departure")!,
    numberOfGuests: Number(searchParams.get("numberOfGuests")!),
  });
  const navigateToExternal = useNavigateToExternal();
  const {
    data: inquiry,
    create,
    loading: creating,
    error,
  } = useCreateInquiry();
  const [openInquiry, setOpenInquiry] = useState<boolean>(false);

  useEffect(() => {
    if (inquiry) {
      if (inquiry.paymentUrl) {
        // Will open stripe checkout session in new tab - this cuts one step in the process increasing conversion
        navigateToExternal(inquiry.paymentUrl, true);
      }

      // App navigates to the finalize page
      navigate(`/gp/${inquiry.id}/finalize`);
    }
  }, [inquiry, navigateToExternal]);

  const handleInquiryChange = useCallback(
    (inquiryParams: InquiryParam) => {
      setOpenInquiry(false);

      setFormValues({
        ...formValues,
        checkinDate: inquiryParams.arrival,
        checkoutDate: inquiryParams.departure,
        numberOfGuests: inquiryParams.numberOfGuests,
      });
    },
    [setFieldValue, searchParams]
  );

  return (
    <>
      <DiscoveryHeader></DiscoveryHeader>

      {data && (
        <PropertyInquiryBottomsheet
          defaultInquiry={{
            arrival: formValues.checkinDate,
            departure: formValues.checkoutDate,
            numberOfGuests: formValues.numberOfGuests,
          }}
          listing={data}
          onDismiss={() => setOpenInquiry(false)}
          open={openInquiry}
          onSelected={(inquiry) => {
            setOpenInquiry(false);
            handleInquiryChange(inquiry);
          }}
        />
      )}

      <Container>
        <Flex justifyContent={"space-between"} gap={36} paddingBottom={32}>
          <Flex direction={"column"} grow={1}>
            <Flex alignItems={"center"}>
              <Link href={`/listings/${data?.id}`}>
                <MdChevronLeft size={24}></MdChevronLeft>
              </Link>
              <Heading level={1}>{t("Property.Book")}</Heading>
            </Flex>

            {data && isMatchingMobile && (
              <Flex direction={"column"}>
                <Flex alignItems={"center"}>
                  <TextMuted>{data.name}</TextMuted>
                </Flex>
                <Flex justifyContent={"space-between"}>
                  <Flex alignItems={"center"}>
                    <MdDateRange></MdDateRange>
                    <TextMuted>
                      {formValues.checkinDate} - {formValues.checkoutDate}
                    </TextMuted>
                  </Flex>

                  <Flex alignItems={"center"} gap={8}>
                    <MdPerson></MdPerson>
                    <TextMuted>{formValues.numberOfGuests}</TextMuted>
                  </Flex>
                  <Flex alignItems={"center"} gap={8}>
                    <Button
                      variation="link"
                      onClick={() => setOpenInquiry(true)}
                    >
                      <MdEdit></MdEdit>
                    </Button>
                  </Flex>
                </Flex>
                <Divider></Divider>
              </Flex>
            )}

            <TextField
              label={t("Account.FirstName")}
              name="firstName"
              type="text"
              onChange={(event) => {
                setFieldValue("guestFirstName", event.target.value);
              }}
            ></TextField>
            <TextField
              name="lastName"
              label={t("Account.LastName")}
              type="text"
              onChange={(event) => {
                setFieldValue("guestLastName", event.target.value);
              }}
            ></TextField>
            <TextField
              label={t("Account.Email")}
              type="email"
              name="email"
              onChange={(event) => {
                setFieldValue("guestEmail", event.target.value);
              }}
            ></TextField>
            <TextField
              name="phone"
              label={t("Account.Phone")}
              onChange={(event) => {
                setFieldValue("phone", event.target.value);
              }}
            ></TextField>

            <TextMuted>
              <Trans
                i18nKey={"FinalizeBooking.AcceptTerms"}
                components={{
                  terms: (
                    <a href={termsLink} target="_blank" rel="noreferrer">
                      terms
                    </a>
                  ),
                  privacy: (
                    <a href={privacyLink} target="_blank" rel="noreferrer">
                      privacy
                    </a>
                  ),
                }}
              />
            </TextMuted>

            <Button
              size="large"
              isLoading={creating}
              variation="primary"
              onClick={async () => {
                await create({
                  guestEmail: formValues.guestEmail!,
                  hostawayId: formValues.hostawayId!,
                  checkinDate: formValues.checkinDate!,
                  checkoutDate: formValues.checkoutDate!,
                  numberOfGuests: formValues.numberOfGuests!,
                  guestFirstName: formValues.guestFirstName!,
                  guestLastName: formValues.guestLastName!,
                  phone: formValues.phone!,
                  guestLocale: i18n.language,
                });
              }}
              disabled={
                !formValues.guestEmail ||
                !formValues.checkinDate ||
                !formValues.guestFirstName ||
                !formValues.guestLastName ||
                !formValues.phone
              }
            >
              {creating && <Loader />}
              {!creating && t("Inquiry.AgreeAndContinue")}
            </Button>
            {error?.message.includes(InquiryErrors.NotAvailable) && (
              <Alert variation="error" isDismissible={false} hasIcon={true}>
                {t("GuestReservation.NotAvailable")}
              </Alert>
            )}
            {error?.message.includes(InquiryErrors.Unknown) && (
              <Alert variation="error" isDismissible={false} hasIcon={true}>
                {t("GuestReservation.FailedMessage")}
              </Alert>
            )}

            <Flex marginTop={16} marginBottom={16}>
              <Divider />
            </Flex>

            <TextMuted>{t("FinalizeBooking.MarketingOptoutDesc")}</TextMuted>

            <CheckboxField
              name="privacy"
              onChange={() => {
                setFieldValue("marketingOptOut", !formValues.marketingOptOut);
              }}
              value={"privacy"}
              label={t("FinalizeBooking.MarketingOptout")}
            ></CheckboxField>
          </Flex>

          {!isMatchingMobile && (
            <Flex maxWidth={450}>
              {data && (
                <PropertyInquiryForm
                  imageUrl={data.listingImages[0].url}
                  onChange={handleInquiryChange}
                  listing={data}
                ></PropertyInquiryForm>
              )}
            </Flex>
          )}
        </Flex>
      </Container>
    </>
  );
}
