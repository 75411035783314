import { apiEndpoint } from "../../../config";
import { logger } from "../../../logger";
import { prepareHerokuRequest } from "../hooks/common";

interface APIGetParams {
  headers: {
    Authorization: string;
  };
  responseType?: "blob" | "json";
  method?: string;
  body?: any;
}

const makeRequest = async (
  relativeUrl: string,
  params?: Partial<APIGetParams>
) => {
  if (params) {
    params.body =
      typeof params.body === "string" || typeof params.body === "undefined"
        ? params.body
        : JSON.stringify(params.body);
  }

  let authReq;

  try {
    authReq = await prepareHerokuRequest();
  } catch (err) {
    // not logged in
  }

  const response = await fetch(`${apiEndpoint}${relativeUrl}`, {
    ...authReq,
    ...params,
  });

  if (response.status < 200 || response.status > 299) {
    const msg = await response
      .json()
      .then(
        (data?: { message?: string }) => data?.message ?? response.statusText
      )
      .catch(() => response.statusText);

    const logMsg = `Can not fetch data: ${msg}`;

    logger.error(logMsg);

    throw new Error(logMsg);
  }

  const data = await response.json();

  return data;
};

export const API = {
  delete: async (url: string, params: Pick<APIGetParams, "body">) => {
    return makeRequest(url, { ...params, method: "DELETE" });
  },
  put: async (url: string, params: Pick<APIGetParams, "body">) => {
    return makeRequest(url, { ...params, method: "PUT" });
  },
  post: async (url: string, params: Pick<APIGetParams, "body">) => {
    return makeRequest(url, { ...params, method: "POST" });
  },
  get: makeRequest,
  download: async (relativeUrl: string, params?: Partial<APIGetParams>) => {
    if (params) {
      params.body =
        typeof params.body === "string" || typeof params.body === "undefined"
          ? params.body
          : JSON.stringify(params.body);
    }

    const response = await fetch(`${apiEndpoint}${relativeUrl}`, {
      ...(await prepareHerokuRequest({ responseType: "blob" })),
      ...params,
    });

    if (response.status < 200 || response.status > 299) {
      const msg = await response.json();

      const logMsg = `Can not fetch data: ${msg}`;

      logger.error(logMsg);

      throw new Error(logMsg);
    }

    const data = await response.blob();

    return data;
  },
};
