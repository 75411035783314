import { Flex } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { borderBottomStyle } from "../Discovery/commonStyles";
import Headline2 from "../libs/shared/components/Headline2";
import { HAListing } from "../libs/shared/types/pms-hostway-listing";

export default function PropertyAmenitiesBottomsheet({
  open,
  onDismiss,
  listing,
}: {
  listing: HAListing;
  open: boolean;
  onDismiss: () => void;
}) {
  const { t } = useTranslation();

  return (
    <BottomSheet
      onDismiss={onDismiss}
      open={open}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
    >
      <Flex direction={"column"} grow={1}>
        <Flex
          paddingLeft={16}
          paddingRight={16}
          style={{ ...borderBottomStyle }}
        >
          <Headline2>{t("Property.Amenities")}</Headline2>
        </Flex>

        <Flex direction={"column"} style={borderBottomStyle}>
          <Flex justifyContent={"center"} direction={"column"} padding={16}>
            {listing.listingAmenities.map((amenity) => (
              <Flex key={amenity.amenityId}>{amenity.amenityName}</Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </BottomSheet>
  );
}
