import { PropsWithChildren, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CorePlace } from "../hooks/useGuestDiscoveryApi";
import LocationBottomsheet from "./bottomsheets/LocationBottomsheet";
import { DiscoveryContext } from "./components/DiscoveryContext";
import { DiscoveryListing } from "./components/DiscoveryListing";
import isLatLngSearchString from "./isLatLngSearchString";

export default function DiscoveryWrapper({ children }: PropsWithChildren<{}>) {
  const [focusedListing, setFocusedListing] = useState<DiscoveryListing>();
  const [selectedListing, setSelectedListing] = useState<DiscoveryListing>();
  const [selectedMapCenter, setSelectedMapCenter] = useState<
    { lat: number; lng: number } | undefined
  >();
  const [place, setPlace] = useState<Partial<CorePlace>>();
  const [locationBottomSheetOpen, setLocationBottomSheetOpen] = useState(false);
  const params = useParams();

  useEffect(() => {
    const latLng = isLatLngSearchString(params.searchString || "");

    if (latLng) {
      return setSelectedMapCenter(latLng);
    }
  }, [params]);

  return (
    <>
      <DiscoveryContext.Provider
        value={{
          place: place,
          setPlace: setPlace,
          locationBottomSheetOpen,
          setLocationBottomSheetOpen,
          selectedMapCenter,
          setSelectedMapCenter,
          focusedListing,
          setFocusedListing,
          selectedListing,
          setSelectedListing,
        }}
      >
        {children}

        <LocationBottomsheet
          onDismiss={() => setLocationBottomSheetOpen(false)}
          open={locationBottomSheetOpen}
        ></LocationBottomsheet>
      </DiscoveryContext.Provider>
    </>
  );
}
