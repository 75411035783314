import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { GOOGLE_ANALYTICS_ID } from "../config";

ReactGA.initialize(GOOGLE_ANALYTICS_ID);

export const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + window.location.search,
    });
  }, [location.pathname]);

  useEffect(() => {}, [location.pathname]);
};
