import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { GuestSession } from "../types/GuestSession";

interface SessionContextInterface {
  session: GuestSession | undefined;
  setSession: (session: GuestSession) => void;
  clearSession: () => void;
}

const SessionContext = createContext<SessionContextInterface | undefined>(
  undefined
);

export default SessionContext;

export const useSessionContext = () => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error("useSessionContext must be used within a SessionProvider");
  }

  return context;
};

export const SessionProvider = ({ children }: PropsWithChildren) => {
  const [session, setSession] = useState<GuestSession>();

  return (
    <SessionContext.Provider
      value={{ session, setSession, clearSession: () => setSession(undefined) }}
    >
      {children}
    </SessionContext.Provider>
  );
};
