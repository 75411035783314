export const theme = {
  background: "white",
  primaryColor: "#213458",
  secondaryColor: "#1D7D81",
  muted: "#6a6a6a",
  secondaryColor30: "#1D7D814D",
  lines: "#DDE2E2",
  background1: "#F1F6F6",
  accentColor: "#DDDDDD",
  errorColor: "#f07f5e",
  success: "green",
};
