import { Heading, Text, TextProps } from "@aws-amplify/ui-react";
import { PropsWithChildren } from "react";
import { theme } from "../../theme";

export const Heading2 = ({ children }: PropsWithChildren) => (
  <Heading level={2} marginBottom="20px" fontWeight={600}>
    {children}
  </Heading>
);

export const Paragraph = ({ children }: PropsWithChildren) => (
  <Text color={theme.muted} fontSize={20}>
    {children}
  </Text>
);

export const ParagraphHeading = ({
  children,
  color,
}: PropsWithChildren<TextProps>) => (
  <Text color={color} fontSize={20} fontWeight={600}>
    {children}
  </Text>
);

export const TextBold = ({ children, color }: PropsWithChildren<TextProps>) => (
  <Text color={color} fontWeight={600}>
    {children}
  </Text>
);

export const TextMuted = ({
  children,
  ...props
}: PropsWithChildren<TextProps>) => (
  <Text color={theme.muted} {...props}>
    {children}
  </Text>
);
