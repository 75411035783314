import React, { PropsWithChildren, useEffect } from "react";
import i18n from "../../../i18n/i18n";
import { useUserProfile } from "./UserProfileProvider";

export default function UserLanguageApplier({ children }: PropsWithChildren) {
  const { userProfile } = useUserProfile();

  useEffect(() => {
    (() => {
      const language = userProfile?.language?.toLowerCase();

      if (language) {
        i18n.setLanguage(language);
      }
    })();
  }, [userProfile]);

  return <>{children}</>;
}
