import { Flex, Heading } from "@aws-amplify/ui-react";
import { HAListing } from "../libs/shared/types/pms-hostway-listing";
import { useTranslation } from "react-i18next";

const PropertyNeighboorhood = ({
  listing: { airbnbNeighborhoodOverview, airbnbTransit },
}: {
  listing: HAListing;
}) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap="20px">
      <Flex>
        <Heading level={2}>{t("Property.Neighborhood")}</Heading>
      </Flex>
      <Flex whiteSpace="pre-wrap">{airbnbNeighborhoodOverview}</Flex>

      <Flex>
        <Heading level={2}>{t("Property.Transit")}</Heading>
      </Flex>
      <Flex whiteSpace="pre-wrap">{airbnbTransit}</Flex>
    </Flex>
  );
};

export default PropertyNeighboorhood;
