export interface ICache<T> {
  get: () => T | undefined;
  set: (properties: T) => void;
  clear: () => void;
}

export const initCache = <T>(cacheId: string): ICache<T> => {
  return {
    get: () => {
      try {
        return JSON.parse(localStorage.getItem(`cache-${cacheId}`)!) as T;
      } catch (err) {
        return;
      }
    },
    set: (properties: T) => {
      localStorage.setItem(`cache-${cacheId}`, JSON.stringify(properties));
    },
    clear: () => {
      localStorage.removeItem(`cache-${cacheId}`);
    },
  };
};

export const clearAllCache = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)!;
    if (key.startsWith("cache-")) {
      localStorage.removeItem(key);
    }
  }
};
