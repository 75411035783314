import { Flex } from "@aws-amplify/ui-react";
import {
  MdBalcony,
  MdElevator,
  MdStarOutline,
  MdTv,
  MdWifi,
  MdWifiCalling,
} from "react-icons/md";

export enum PropertyTopAmenity {
  Wifi = "Wifi",
  Wifi250 = "WiFi speed (250+ Mbps)",
  FreeWifi = "Free WiFi",
  FreeParking = "Free parking",
  AirConditioning = "Air conditioning",
  Balcony = "Balcony",
  Elevator = "Elevator",
  SmartTV = "Smart TV",
}

const propertyAmenityIcons = {
  [PropertyTopAmenity.Balcony]: <MdBalcony />,
  [PropertyTopAmenity.Wifi]: <MdWifi />,
  [PropertyTopAmenity.FreeWifi]: <MdWifi />,
  [PropertyTopAmenity.Wifi250]: <MdWifiCalling />,
  [PropertyTopAmenity.AirConditioning]: <MdStarOutline />,
  [PropertyTopAmenity.FreeParking]: <MdBalcony />,
  [PropertyTopAmenity.Elevator]: <MdElevator />,
  [PropertyTopAmenity.SmartTV]: <MdTv />,
};

export function PropertyTopAmenityComponent({
  amenity,
}: {
  amenity: PropertyTopAmenity;
}) {
  if (propertyAmenityIcons[amenity]) {
    return (
      <Flex alignItems={"center"}>
        {propertyAmenityIcons[amenity]}
        {amenity}
      </Flex>
    );
  }

  return <Flex>{amenity}</Flex>;
}
