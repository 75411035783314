import { Flex, Heading } from "@aws-amplify/ui-react";
import { HAListing } from "../libs/shared/types/pms-hostway-listing";
import { useTranslation } from "react-i18next";

const PropertyIntroSection = ({
  listing: {
    city,
    personCapacity,
    bedroomsNumber,
    bedsNumber,
    name,
    bathroomsNumber,
  },
}: {
  listing: HAListing;
}) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={4}>
      <Flex>
        <Heading level={2}>
          {t("Property.EntirePropertyIn")}: {city}
        </Heading>
      </Flex>

      <Flex>
        {personCapacity} {t("Property.IntroGuests")} · {bedroomsNumber}{" "}
        {t("Property.IntroBedrooms")} · {bedsNumber} {t("Property.IntroBeds")}·{" "}
        {bathroomsNumber} {t("Property.IntroBathrooms")}
      </Flex>
    </Flex>
  );
};

export default PropertyIntroSection;
