import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { apiEndpoint } from "../config";
import { logger } from "../logger";
import { buildQueryString } from "../utils/buildQueryString";
import { initCache } from "./useCache";

export interface ISuccessfulFetch<T> {
  status: "success";
  result: T;
}

export const unmarshallDates = (
  data: Record<string, any>,
  dateKeys: string[]
) => {
  const unmarshalled = { ...data };

  dateKeys.forEach((key) => {
    if (data[key] === null || data[key] === undefined) {
      return;
    }

    unmarshalled[key] = new Date(data[key]);
  });

  return unmarshalled;
};

export const prepareQueryString = (params: Record<string, any>) => {
  const keys = Object.keys(params);

  if (!keys.length) {
    return "";
  }

  return `?${keys
    .filter((key) => params[key] !== undefined)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&")}`;
};

export const prepareHerokuRequest = async (
  init: { body?: any; headers?: Record<string, any> } = {}
) => ({
  ...init,
  headers: {
    ...(init.headers || {}),
    Authorization: `Bearer ${(await Auth.currentSession())
      .getIdToken()
      .getJwtToken()}`,
  },
});

export const getAuthHeader = async (auxHeaders: Record<string, any> = {}) => ({
  headers: {
    ...auxHeaders,
    Authorization: `Bearer ${(await Auth.currentSession())
      .getIdToken()
      .getJwtToken()}`,
  },
});

type UnmarshalType = "date" | "float";
type UnmarshalConfig<T> = Partial<{
  [key in keyof T]: UnmarshalType;
}>;

export const useLazyApi = <T extends Object>(
  relativeUrl: string,
  config: { body?: any; headers?: Record<string, any> } = {},
  cacheKey?: string
) => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const cache = cacheKey ? initCache<T | undefined>(cacheKey) : undefined;

  const load = async (queryParams?: Record<string, string | number>) => {
    const cachedData = cache?.get();
    try {
      if (cachedData) {
        // show cached (possibly outdated) data while fetching new data
        setData(cachedData);
      } else {
        // only show loading indicator if we don't have cached values
        setIsLoading(true);
      }

      const response = await fetch(
        `${apiEndpoint}/${relativeUrl}${buildQueryString(queryParams!)}`,
        await prepareHerokuRequest(config)
      );

      if (response.status < 200 || response.status > 299) {
        const msg = await response
          .json()
          .then(
            (data?: { message?: string }) =>
              data?.message ?? response.statusText
          )
          .catch(() => response.statusText);

        logger.error(`Can not fetch data: ${msg}`);
        throw new Error(`Can not fetch data: ${msg}`);
      }

      const data = await response.json();

      cache?.set(data);
      setData(data);
    } catch (error) {
      setError(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, load };
};

export const useLazyHerokuApi = <T extends Object>(
  relativeUrl: string,
  config: { body?: any; headers?: Record<string, any> } = {},
  cacheKey?: string,
  unmarshalConfig?: UnmarshalConfig<T>
) => {
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const cache = cacheKey ? initCache<T[]>(cacheKey) : undefined;

  const unmarshalData = (config: UnmarshalConfig<T>, data: T[]) => {
    for (const item of data) {
      for (const [key, value] of Object.entries(item) as [
        keyof T,
        T[keyof T]
      ][]) {
        if (config[key as keyof T] === "date") {
          item[key] = new Date(value as string) as T[keyof T];
        }

        if (config[key as keyof T] === "float") {
          item[key] = parseFloat(value as string) as T[keyof T];
        }
      }
    }
  };

  const load = async (queryParams?: Record<string, string | number>) => {
    const cachedData = cache?.get();
    try {
      if (cachedData) {
        // show cached (possibly outdated) data while fetching new data
        setData(cachedData);
      } else {
        // only show loading indicator if we don't have cached values
        setIsLoading(true);
      }

      const response = await fetch(
        `${apiEndpoint}/${relativeUrl}${buildQueryString(queryParams!)}`,
        await prepareHerokuRequest(config)
      );

      if (response.status < 200 || response.status > 299) {
        const msg = await response
          .json()
          .then(
            (data?: { message?: string }) =>
              data?.message ?? response.statusText
          )
          .catch(() => response.statusText);

        logger.error(`Can not fetch data: ${msg}`);
        throw new Error(`Can not fetch data: ${msg}`);
      }

      const data = await response.json();

      if (unmarshalConfig) {
        unmarshalData(unmarshalConfig, data);
      }

      cache?.set(data);
      setData(data);
    } catch (error) {
      setError(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, load };
};

export const useHerokuApi = <T extends Object>(
  relativeUrl: string,
  config: { body?: any; headers?: Record<string, any> } = {},
  cacheKey?: string,
  unmarshalConfig?: UnmarshalConfig<T>
) => {
  const { load, data, isLoading, error } = useLazyHerokuApi(
    relativeUrl,
    config,
    cacheKey,
    unmarshalConfig
  );

  useEffect(() => {
    load();
  }, []);

  return { data, isLoading, error };
};
