import { Link } from "@aws-amplify/ui-react";
import React from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "../libs/shared/components/Box";

export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "left",
        paddingTop: 60,
        marginTop: "auto",
      }}
    >
      <Box style={{ textAlign: "center", flexGrow: 1 }} mb={35}>
        <Button variant="light" size="sm">
          <Link href="/" textDecoration="underline dotted" isExternal={false}>
            {t("Home")}
          </Link>
        </Button>

        <Button
          variant="light"
          size="sm"
          onClick={() => navigate("/investor-cockpit")}
        >
          <Link textDecoration="underline dotted">{t("Kokpit Inwestora")}</Link>
        </Button>

        <Button
          variant="light"
          size="sm"
          onClick={() => navigate("/owner-cockpit")}
        >
          <Link textDecoration="underline dotted">{t("OwnerCockpit")}</Link>
        </Button>

        <Button variant="light" size="sm" onClick={() => navigate("/account")}>
          <Link textDecoration="underline dotted">{t("Konto")}</Link>
        </Button>

        <Button variant="light" size="sm">
          <Link
            href="https://www.rentujemy.pl/imprint"
            textDecoration="underline dotted"
            isExternal={false}
          >
            {t("Impressum")}
          </Link>
        </Button>
      </Box>
    </Box>
  );
}
