import axios, { AxiosError } from "axios";
import { useCallback, useState } from "react";
import { apiEndpoint } from "../config";

export const publicLogin = async (data: {
  reservationId?: string;
  guestLastName?: string;
}): Promise<{ status: "verified" }> => {
  return (
    await axios.post(`${apiEndpoint}/v1/guest/account/public-login`, data)
  ).data;
};

export const useGuestAccountApi = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>();

  const guestPublicLogin = useCallback(
    (data: { reservationId: string; guestLastName: string }) => {
      setLoading(true);

      (async () => {
        try {
          const newData = !!(await publicLogin(data)).status;
          setData(newData);
        } catch (err) {
          setError(err as AxiosError);
        } finally {
          setLoading(false);
        }
      })();
    },
    [setLoading, setData, setError]
  );

  return { data, loading, error, guestPublicLogin };
};
