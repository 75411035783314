import { Button } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { borderBottomStyle } from "../commonStyles";

export default function NavButton({
  pathname,
  label,
}: {
  pathname: string;
  label: string;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState("");

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  return (
    <Button
      variation="link"
      style={currentLocation === pathname ? borderBottomStyle : undefined}
      onClick={() => {
        navigate(pathname);
      }}
    >
      {label}
    </Button>
  );
}
