import SEO from "@americanexpress/react-seo";
import { Flex } from "@aws-amplify/ui-react";
import { PropsWithChildren } from "react";
import { isMobile, isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DiscoveryHeader from "../../Discovery/components/DiscoveryHeader";
import MobileNavTabs, {
  NAV_TABS_HEIGHT,
} from "../../Discovery/components/MobileNavTabs";
import useBreakpoints from "../../hooks/useBreakpoints";

export default function GuestLayout({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const params = useParams();
  const { isMatchingDesktop } = useBreakpoints();
  return (
    <>
      <DiscoveryHeader />
      <SEO
        title={`${params.reservationId ? params.reservationId + " - " : ""}${t(
          "SEO.GuestPortal.Title"
        )}`}
        description={t("SEO.GuestPortal.Description")}
        keywords={[
          "rent",
          "apartments",
          "short-term rental",
          "poland",
          "guest portal",
        ]}
        siteUrl="https://rentujemy.com"
        image={{
          src: "about-hero-img.png",
        }}
      />

      <Flex
        className="container"
        paddingTop={16}
        paddingBottom={isMobile ? 200 : undefined}
        height={`calc(
        100svh -
          ${NAV_TABS_HEIGHT}px -
          ${isMobile && isSafari ? "env(safe-area-inset-bottom) + 0px" : "0px"}
      )`}
      >
        {children}
      </Flex>

      {!isMatchingDesktop && <MobileNavTabs></MobileNavTabs>}
    </>
  );
}
