import { Button, Flex, Heading } from "@aws-amplify/ui-react";
import { HAListing } from "../libs/shared/types/pms-hostway-listing";
import { useTranslation } from "react-i18next";
import useNavigateToExternal from "../libs/shared/hooks/useNavigateToExternal";
import { termsLink } from "../libs/shared/constants";

export default function PropertyReservationTerms({
  listing: { airbnbNeighborhoodOverview },
}: {
  listing: HAListing;
}) {
  const { t } = useTranslation();
  const navigateToExternal = useNavigateToExternal();

  return (
    <Flex direction="column" gap="20px">
      <Flex>
        <Heading level={2}>{t("Property.GoodToKnow")}</Heading>
      </Flex>
      <Flex>
        <Flex direction={"column"} basis={"40%"}>
          <Heading level={3}>{t("Property.Terms")}</Heading>
          <Flex>{t("Property.CheckinTerms")}</Flex>
          <Flex>{t("Property.CheckoutTerms")}</Flex>
        </Flex>
        <Flex direction={"column"} basis={"40%"}>
          <Heading level={3}>{t("Property.CancellationTerms")}</Heading>
          <Flex>{t("Property.CancellationPolicyFlexible")}</Flex>
          <Flex>{t("Property.CancellationPolicyFlexibleText")}</Flex>
          <Flex>
            <Button
              variation="link"
              onClick={() => {
                navigateToExternal(termsLink);
              }}
            >
              {t("Property.ShowMore")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
