import { createContext, useContext } from "react";
import { DiscoveryListing } from "./DiscoveryListing";
import { CorePlace } from "../../hooks/useGuestDiscoveryApi";

export const DiscoveryContext = createContext<{
  focusedListing: DiscoveryListing | undefined;
  setFocusedListing: (focused: DiscoveryListing | undefined) => void;
  selectedListing: DiscoveryListing | undefined;
  setSelectedListing: (focused: DiscoveryListing | undefined) => void;
  selectedMapCenter?: { lat: number; lng: number };
  setSelectedMapCenter: (center?: { lat: number; lng: number }) => void;
  locationBottomSheetOpen: boolean;
  setLocationBottomSheetOpen: (open: boolean) => void;
  place: Partial<CorePlace> | undefined;
  setPlace: (place: Partial<CorePlace> | undefined) => void;
}>({
  locationBottomSheetOpen: false,
  setLocationBottomSheetOpen: () => void 0,
  place: undefined,
  setPlace: () => void 0,
  selectedMapCenter: undefined,
  setSelectedMapCenter: () => void 0,
  focusedListing: undefined,
  setFocusedListing: () => void 0,
  selectedListing: undefined,
  setSelectedListing: () => void 0,
});

export const useDiscoveryContext = () => {
  return useContext(DiscoveryContext);
};
