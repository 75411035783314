import { orderBy } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { ReservationDateRange } from "../../Discovery/components/StayDatePickerRange";

export const usePropertyBlockedDates = ({
  reservations,
  selectedStartDate,
}: {
  selectedStartDate?: string | Date;
  reservations?: ReservationDateRange[];
}) => {
  const excludeEndDates = useMemo(() => {
    const sorted = orderBy(reservations, "start");

    const nextBlocked = selectedStartDate
      ? sorted.find((interval) =>
          moment(interval.start).isSameOrAfter(moment(selectedStartDate))
        )
      : undefined;

    if (nextBlocked) {
      return [
        {
          start: moment(nextBlocked.start).add(1, "day").toDate(),
          end: moment("2099-01-01").toDate(),
        },
      ];
    }

    return (reservations || []).map((date) => ({
      start: moment(date.start).add(1, "day").toDate(),
      end: moment(date.end).toDate(),
    }));
  }, [selectedStartDate, reservations]);

  const excludeStartDates = useMemo(() => {
    return (reservations || []).map((date) => ({
      start: moment(date.start).add(1, "day").toDate(),
      end: moment(date.end).subtract(1, "day").toDate(),
    }));
  }, [selectedStartDate, reservations]);

  return {
    excludeEndDates,
    excludeStartDates,
  };
};
