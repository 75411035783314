import { Flex, Placeholder } from "@aws-amplify/ui-react";

export default function PropertyPlaceholder() {
  return (
    <Flex grow={1} direction={"column"}>
      <Placeholder height={30}></Placeholder>
      <Flex grow={1} direction={"row"}>
        <Flex grow={1} basis="40%" direction={"column"}>
          <Placeholder height={300}></Placeholder>
        </Flex>
        <Flex grow={1} gap={10}>
          <Flex direction={"column"}>
            <Placeholder height={140}></Placeholder>
            <Placeholder height={140}></Placeholder>
          </Flex>
          <Flex grow={1} direction={"column"}>
            <Placeholder height={140}></Placeholder>
            <Placeholder height={140}></Placeholder>
          </Flex>
        </Flex>
      </Flex>

      <Flex>
        <Flex basis={"60%"} direction={"column"} gap={10}>
          <Placeholder height={30}></Placeholder>
          <Placeholder height={30}></Placeholder>
          <Placeholder height={140}></Placeholder>
        </Flex>
        <Flex grow={1}>
          <Placeholder height={220}></Placeholder>
        </Flex>
      </Flex>
    </Flex>
  );
}
