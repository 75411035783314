import { Flex, Heading } from "@aws-amplify/ui-react";
import { HAListing } from "../libs/shared/types/pms-hostway-listing";

export default function PropertyHeaderSection({
  listing: { name },
}: {
  listing: HAListing;
}) {
  return (
    <Flex direction="row" gap="20px">
      <Flex>
        <Heading level={1}>{name}</Heading>
      </Flex>
    </Flex>
  );
}
