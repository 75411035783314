import {
  Button,
  ButtonProps,
  Flex,
  Heading,
  Text,
} from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { MdAdd, MdRemove } from "react-icons/md";
import { PropsWithChildren, useEffect, useState } from "react";

function CircleButton({
  children,
  size = 40,
  ...buttonProps
}: PropsWithChildren<
  {
    size?: number;
    onClick: () => void;
  } & Pick<ButtonProps, "onClick" | "isDisabled">
>) {
  return (
    <Button
      variation="primary"
      borderRadius="50%"
      padding={4}
      width={size}
      height={size}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...buttonProps}
    >
      {children}
    </Button>
  );
}

export default function NumberOfGuestField({
  onChange,
  capacity = 10,
  defaultValue = 2,
}: {
  capacity: number;
  defaultValue: number;
  onChange: (numberOfGuests: number) => void;
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(defaultValue);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"}>
          <Heading>{t("Discovery.Search.Guests")}</Heading>

          <Text>{value}</Text>
        </Flex>
        <Flex>
          <CircleButton
            size={30}
            isDisabled={value === 1}
            onClick={() => {
              setValue(value - 1);
            }}
          >
            <MdRemove size={20} />
          </CircleButton>

          <CircleButton
            size={30}
            isDisabled={value >= capacity}
            onClick={() => {
              const newValue = value + 1;

              if (newValue <= capacity) {
                setValue(value + 1);
              }
            }}
          >
            <MdAdd size={40} />
          </CircleButton>
        </Flex>
      </Flex>
    </>
  );
}
