import { Flex, Text } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { MdCheckCircleOutline } from "react-icons/md";

export default function USPCompactSection() {
  const { t } = useTranslation();

  return (
    <Flex justifyContent={"center"} direction={"column"}>
      <Text textAlign={"center"}>
        <MdCheckCircleOutline></MdCheckCircleOutline> {t("Discovery.USP.USP1")}
      </Text>
      <Text textAlign={"center"}>
        <MdCheckCircleOutline></MdCheckCircleOutline> {t("Discovery.USP.USP2")}
      </Text>
      <Text textAlign={"center"}>
        <MdCheckCircleOutline></MdCheckCircleOutline> {t("Discovery.USP.USP3")}
      </Text>
    </Flex>
  );
}
