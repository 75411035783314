import type { IDictionary } from "./IDictionary";

import enJson from "./en.json";
import plJson from "./pl.json";

export const en: IDictionary = enJson;
export const pl: IDictionary = plJson;

export const awsAuthPl = {
  "Incorrect username or password": "Niepoprawna nazwa użytkownika lub hasło",
  "Your passwords must match": "Twoje hasła muszą być zgodne",
  "Invalid verification code provided, please try again.":
    "Podano nieprawidłowy kod weryfikacyjny, spróbuj ponownie.",
  "Your code is on the way. To log in, enter the code we emailed to":
    "Kod potwierdzający został wysłany. Wpisz kod tutaj, który otrzymałeś ma emaila",
  "It may take a minute to arrive.": "To może zająć chwilkę zanim dotrze.",
  "We Emailed You": "Sprawdź skrzynkę pocztową",
  "Username cannot be empty": "Nazwa użytkownika nie może być pusta",
  "User does not exist.": "Użytkownik nie istnieje.",
  "An account with the given email already exists.":
    "Konto z podanym adresem e-mail już istnieje.",
  "User is disabled.": "Użytkownik jest zablokowany.",
  "Incorrect username or password.": "Niepoprawna nazwa użytkownika lub hasło.",
  "Custom auth lambda trigger is not configured for the user pool.":
    "Należy podać hasło.",
  "Password cannot be empty": "Hasło nie może być puste",
};
