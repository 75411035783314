import { Flex, Heading, Text } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { MdCheckCircleOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { theme } from "../../theme";

export default function EarlyCheckingSuccessPage() {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <>
      <Flex direction={"column"} gap={40}>
        <Flex direction={"column"}>
          <Flex direction={"row"} alignItems={"center"}>
            <Flex>
              <MdCheckCircleOutline
                size={50}
                color={theme.success}
              ></MdCheckCircleOutline>
            </Flex>
            <Flex>
              <Heading level={3}>{t("EarlyCheckin.Success")}</Heading>
            </Flex>
          </Flex>
          <Flex>
            <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
              {t("Reservation.Id")}: {params.reservationId}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
