import { Divider, Flex, Placeholder } from "@aws-amplify/ui-react";
import { useEffect, useMemo } from "react";
import {
  HABookableListing,
  usePropertyPrice,
} from "../libs/shared/hooks/useProperty";
import { useTranslation } from "react-i18next";
import { InquiryParam } from "./InquiryParam";
import { TextBold } from "../Discovery/components/DiscoveryTypography";

export default function PropertyInquiryPricing({
  inquiry,
  listing: { id },
}: {
  inquiry: InquiryParam;
  imageUrl?: string;
  onChange?: (params: InquiryParam) => void;
  displayCtaButton?: boolean;
  listing: Pick<HABookableListing, "id">;
}) {
  const { t } = useTranslation();

  const {
    data: priceData,
    load: loadPrice,
    loading,
  } = usePropertyPrice(Number(id));

  const canCalculate = useMemo(() => {
    return inquiry.arrival && inquiry.departure && inquiry.numberOfGuests;
  }, [inquiry]);

  useEffect(() => {
    if (canCalculate) {
      loadPrice({
        arrivalDate: inquiry.arrival!,
        departureDate: inquiry.departure!,
        numberOfGuests: inquiry.numberOfGuests!,
      });
    }
  }, [inquiry, canCalculate]);

  return (
    <Flex direction="column" gap="20px">
      {loading && (
        <Flex direction={"column"}>
          <Placeholder height={35}></Placeholder>
          <Placeholder height={35}></Placeholder>
          <Placeholder height={35}></Placeholder>
          <Placeholder height={35}></Placeholder>
        </Flex>
      )}

      {!loading && priceData && canCalculate && (
        <Flex direction={"column"} gap={6}>
          <Flex justifyContent={"space-between"}>
            <Flex>{t("Inquiry.BasePrice")}</Flex>
            <Flex>{priceData.baseRate} zł</Flex>
          </Flex>
          {!!Number(priceData.monthlyDiscount) && (
            <Flex justifyContent={"space-between"}>
              <Flex>{t("Inquiry.MonthlyDiscount")}</Flex>
              <Flex>{priceData.monthlyDiscount} zł</Flex>
            </Flex>
          )}
          {!!Number(priceData.weeklyDiscount) && (
            <Flex justifyContent={"space-between"}>
              <Flex>{t("Inquiry.WeeklyDiscount")}</Flex>
              <Flex>{priceData.weeklyDiscount} zł</Flex>
            </Flex>
          )}
          {!!Number(priceData.pricePerExtraPerson) && (
            <Flex justifyContent={"space-between"}>
              <Flex>{t("Inquiry.AdditionalPersons")}</Flex>
              <Flex>{priceData.pricePerExtraPerson} zł</Flex>
            </Flex>
          )}
          <Flex justifyContent={"space-between"}>
            <Flex>{t("Inquiry.Tax")}</Flex>
            <Flex>{priceData.vat} zł</Flex>
          </Flex>
          <Flex justifyContent={"space-between"}>
            <Flex>{t("Inquiry.CleaningFee")}</Flex>
            <Flex>{priceData.cleaningFee} zł</Flex>
          </Flex>
          <Divider></Divider>
          <Flex justifyContent={"space-between"}>
            <Flex>
              <TextBold>{t("Inquiry.Total")}</TextBold>
            </Flex>
            <Flex>
              <TextBold>{priceData.totalPrice} zł</TextBold>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
