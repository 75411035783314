import { Flex, FlexProps, Image } from "@aws-amplify/ui-react";
import FsLightbox from "fslightbox-react";
import { useMemo, useState } from "react";
import { HAListing } from "../libs/shared/types/pms-hostway-listing";

const ImageBox = ({
  url,
  onPress,
  ...flexProps
}: FlexProps & {
  url: string;
  onPress: (url: string) => void;
}) => {
  return (
    <Flex
      {...flexProps}
      direction="column"
      onClick={() => {
        onPress(url);
      }}
    >
      <Image
        overflow={"hidden"}
        width={"100%"}
        height={"100%"}
        alt={"Property Image"}
        src={url}
        objectFit={"cover"}
      ></Image>
    </Flex>
  );
};

export default function PropertyImages({
  listingImages,
}: Pick<HAListing, "listingImages">) {
  const [selectedImage, setSelected] = useState<string | undefined>(undefined);

  const urls = useMemo(() => {
    return listingImages.map((image) => image.url);
  }, [listingImages]);

  return (
    <Flex direction="row" gap="20px" justifyContent={"center"} maxHeight={454}>
      <Flex basis={"50%"}>
        <ImageBox
          url={listingImages[0].url}
          onPress={(url) => {
            setSelected(url);
          }}
        />
      </Flex>

      <Flex direction={"column"} basis={"50%"}>
        <Flex maxHeight={220}>
          <ImageBox
            url={listingImages[2].url}
            onPress={(url) => {
              setSelected(url);
            }}
          />
          <ImageBox
            url={listingImages[3].url}
            onPress={(url) => {
              setSelected(url);
            }}
          />
        </Flex>
        <Flex maxHeight={220}>
          <ImageBox
            url={listingImages[4].url}
            onPress={(url) => {
              setSelected(url);
            }}
          />

          <ImageBox
            url={listingImages[5].url}
            onPress={(url) => {
              setSelected(url);
            }}
          />
        </Flex>
      </Flex>

      <FsLightbox
        type={"image"}
        onClose={() => {
          setSelected(undefined);
        }}
        toggler={!!selectedImage}
        sources={urls}
        source={selectedImage}
      />
    </Flex>
  );
}
