import { Button, Flex, Heading } from "@aws-amplify/ui-react";
import { HAListing } from "../libs/shared/types/pms-hostway-listing";
import PropertyAmenitiesBottomsheet from "./PropertyAmenitiesBottomsheet";
import { useMemo, useState } from "react";
import {
  PropertyTopAmenity,
  PropertyTopAmenityComponent,
} from "./PropertyTopAmenity";
import { map, uniq } from "lodash";
import { useTranslation } from "react-i18next";

export default function PropertyAmenities({ listing }: { listing: HAListing }) {
  const { t } = useTranslation();
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const topAmenities = useMemo(() => {
    const topAmenities: PropertyTopAmenity[] = (
      map(
        listing.listingAmenities.filter((amenity) =>
          Object.values(PropertyTopAmenity).includes(
            amenity.amenityName as PropertyTopAmenity
          )
        ),
        "amenityName"
      ) as PropertyTopAmenity[]
    ).map((amenityName) => {
      if (
        [PropertyTopAmenity.FreeWifi, PropertyTopAmenity.Wifi].includes(
          amenityName
        )
      ) {
        return PropertyTopAmenity.Wifi;
      }

      return amenityName;
    });

    const uniqueAmenities = uniq(topAmenities);

    return {
      left: uniqueAmenities.slice(0, 3),
      right: uniqueAmenities.slice(3, 6),
    };
  }, [listing]);

  return (
    <>
      <PropertyAmenitiesBottomsheet
        listing={listing}
        onDismiss={() => setOpenDetails(false)}
        open={openDetails}
      />
      <Flex direction="column" gap="20px">
        <Flex>
          <Heading level={2}>{t("Property.Amenities")}</Heading>
        </Flex>

        <Flex grow={1} gap={42}>
          <Flex basis={"50%"} direction={"column"}>
            {topAmenities.left.map((amenity, index) => (
              <PropertyTopAmenityComponent
                key={`top-amenity-left-${index}`}
                amenity={amenity}
              />
            ))}
          </Flex>
          <Flex direction={"column"}>
            {topAmenities.right.map((amenity, index) => (
              <PropertyTopAmenityComponent
                key={`top-amenity-right-${index}`}
                amenity={amenity}
              />
            ))}
          </Flex>
        </Flex>
        <Button
          variation="link"
          onClick={() => {
            setOpenDetails(true);
          }}
        >
          {t("Property.ShowMore")}
        </Button>
      </Flex>
    </>
  );
}
