export default function isLatLngSearchString(searchString: string) {
  const splitSearchQuery = searchString?.split(",");
  const lat = splitSearchQuery ? Number(splitSearchQuery[0]) : undefined;
  const lng = splitSearchQuery ? Number(splitSearchQuery[1]) : undefined;

  if (lat && lng) {
    return { lat, lng };
  }
  return null;
}
