import { Flex, Text } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { MdOutlineFacebook } from "react-icons/md";
import { RxInstagramLogo } from "react-icons/rx";
import { Link } from "react-router-dom";
import PressableExternalLink from "./PressableExternalLink";
import { useDestinations } from "../../hooks/useGuestDiscoveryApi";
import {
  facebookLink,
  instagramLink,
  privacyLink,
  termsLink,
} from "../../libs/shared/constants";

const SECTION_WIDTH = 1250;

export default function DiscoveryFooter() {
  const { t } = useTranslation();
  const { data: destinations } = useDestinations();

  return (
    <Flex
      direction="column"
      padding="32px"
      backgroundColor="#f0f0f0"
      borderRadius="10px"
      alignItems={"center"}
    >
      <Flex maxWidth={SECTION_WIDTH} width={"100%"} direction={"column"}>
        <Flex direction="row" justifyContent={"flex-start"} gap={52}>
          <Flex direction="column" gap={12}>
            <Text>
              <strong>{t("Discovery.Footer.Support")}</strong>
            </Text>
            <Text>
              <Link to={"/owner-cockpit"} style={{ textDecoration: "none" }}>
                <strong>{t("Discovery.Footer.OwnerPortal")}</strong>
              </Link>
            </Text>

            <Text>
              <PressableExternalLink url={"https://www.rentujemy.pl"}>
                <strong>{t("Discovery.Footer.AddApartment")}</strong>
              </PressableExternalLink>
            </Text>

            <Text>
              <PressableExternalLink url={termsLink}>
                <strong>{t("Discovery.Footer.TermsOfService")}</strong>
              </PressableExternalLink>
            </Text>

            <Text>
              <PressableExternalLink url={privacyLink}>
                <strong>{t("Discovery.Footer.PrivacyPolicy")}</strong>
              </PressableExternalLink>
            </Text>
          </Flex>

          <Flex direction="column" gap={12}>
            <Text>
              <strong>{t("Discovery.Footer.Destinations")}</strong>
            </Text>

            {destinations?.map((destination) => {
              return (
                <Flex key={`destination-${destination.id}`}>
                  <Text>
                    <PressableExternalLink url={`/s/${destination.id}`}>
                      <strong>
                        {t("Discovery.Footer.Destination", {
                          destination: destination.label,
                        })}
                      </strong>
                    </PressableExternalLink>
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex
          direction="row"
          gap="10px"
          marginTop={32}
          paddingBottom={16}
          justifyContent={"space-between"}
        >
          <Flex justifyContent={"flex-start"}>
            <Text>
              <strong>© RENTUJEMY.COM</strong>
            </Text>
          </Flex>

          <Flex justifyContent={"flex-end"}>
            <Text>
              <PressableExternalLink url={instagramLink}>
                <strong>
                  <RxInstagramLogo></RxInstagramLogo>
                </strong>
              </PressableExternalLink>
            </Text>

            <Text>
              <PressableExternalLink url={facebookLink}>
                <strong>
                  <MdOutlineFacebook></MdOutlineFacebook>
                </strong>
              </PressableExternalLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
