import { Button, Card, Flex, TextField } from "@aws-amplify/ui-react";
import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { TextMuted } from "../../Discovery/components/DiscoveryTypography";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useGuestAccountApi } from "../../hooks/useGuestAccountApi";
import { useSessionContext } from "../providers/SessionProvider";
import USPCompactSection from "../USPCompactSection";

export default function AuthWrapper({
  children,
  isPublic,
}: PropsWithChildren<{ isPublic: boolean }>) {
  const { t } = useTranslation();

  const params = useParams<{ reservationId: string }>();
  const { user } = useCurrentUser();
  const { session, setSession } = useSessionContext();
  const [reservationId, setReservationId] = useState(
    params.reservationId ?? ""
  );
  const [guestLastName, setGuestLastName] = useState("");
  const {
    data: publicLoginValidated,
    guestPublicLogin,
    error,
    loading,
  } = useGuestAccountApi();
  const navigate = useNavigate();
  const createSession = async () => {
    guestPublicLogin({
      reservationId,
      guestLastName,
    });
  };

  useEffect(() => {
    if (user) {
      setSession({
        type: "user",
        reservationId: Number(reservationId),
      });
    }
  }, [user, reservationId, setSession]);

  useEffect(() => {
    if (publicLoginValidated) {
      setSession({
        type: "unregistered",
        reservationId: Number(reservationId),
        guestLastName,
      });
    }
  }, [publicLoginValidated, setSession]);

  useEffect(() => {
    if (isPublic && !session) {
      setSession({
        type: "public",
        reservationId: Number(reservationId),
      });
    }
  }, [isPublic, reservationId, session, setSession]);

  return (
    <>
      {isPublic || session?.type === "unregistered" || user ? (
        children
      ) : (
        <Flex paddingTop={32} padding={16} direction="column">
          <Card variation="outlined" grow={1}>
            <Flex direction="column">
              <TextField
                label={t("GuestLogin.ReservationId")}
                isRequired={true}
                disabled={true}
                readOnly={true}
                value={reservationId}
                onChange={(event) => setReservationId(event.target.value)}
              />

              <TextField
                label={t("GuestLogin.GuestLastName")}
                isRequired={true}
                value={guestLastName}
                onChange={(event) => setGuestLastName(event.target.value)}
              />

              <Button
                disabled={loading}
                isFullWidth={true}
                onClick={createSession}
              >
                {t("GuestLogin.Login")}
              </Button>

              {(error?.response?.data as any)?.message === "NotFound" && (
                <TextMuted>{t("GuestLogin.Invalid")}</TextMuted>
              )}
            </Flex>
          </Card>

          <Flex justifyContent={"center"} padding={8}>
            {t("Common.Or")}
          </Flex>
          <Flex direction={"column"} gap={32}>
            <Card variation="outlined" grow={1}>
              <Flex direction="column">
                <Flex>
                  <Button
                    variation="primary"
                    isFullWidth={true}
                    onClick={() => {
                      navigate("/trips");
                    }}
                  >
                    {t("GuestLogin.ClaimAccount")}
                  </Button>
                </Flex>

                <USPCompactSection></USPCompactSection>
              </Flex>
            </Card>
          </Flex>
        </Flex>
      )}
    </>
  );
}
