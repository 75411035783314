export const KEYWORDS = [
  "rent",
  "apartments",
  "short-term rental",
  "poland",
  "warsaw apartments",
  "vacation rentals",
  "rentujemy",
  "cheap apartments",
];
